<script setup lang="ts">
import type { User } from '@/thunder/modules/user/user.model';
import type { DirectusRoles } from '@thunder/core/src/services/baas/directus/schema';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

const ability = useAbility();

// TODO: Get type from backend
const userData = useCookie<User | null>('userData');

const { signOut } = useAuth();
const { $baas } = useNuxtApp();

async function logout() {
	try {
		await signOut({ redirect: false });
		await $baas.setToken(null);

		// Remove "userData" from cookie
		userData.value = null;

		// Reset user abilities
		ability.update([]);

		navigateTo({ name: 'login' });
	} catch (error: any) {
		throw createError(error);
	}
}

const userProfileList = [
	{ type: 'divider' },
	{
		type: 'navItem',
		icon: 'tabler-user',
		title: 'Test Link',
		to: { name: 'index' },
		badgeProps: { color: 'error', content: '4' },
	},
];
</script>

<template>
	<VBadge
		v-if="userData"
		dot
		bordered
		location="bottom right"
		offset-x="1"
		offset-y="2"
		color="success"
	>
		<VAvatar
			size="38"
			class="cursor-pointer"
			:color="!(userData && userData.avatar) ? 'primary' : undefined"
			:variant="!(userData && userData.avatar) ? 'tonal' : undefined"
		>
			<VImg
				v-if="userData && userData.avatar"
				:src="userData.avatar as string"
			/>
			<VIcon v-else icon="tabler-user" />

			<!-- SECTION Menu -->
			<VMenu activator="parent" width="240" location="bottom end" offset="12px">
				<VList>
					<!-- 👉 User Avatar & Name -->
					<VListItem>
						<template #prepend>
							<VListItemAction start>
								<VBadge
									dot
									location="bottom right"
									offset-x="3"
									offset-y="3"
									color="success"
									bordered
								>
									<VAvatar
										:color="
											!(userData && userData.avatar) ? 'primary' : undefined
										"
										:variant="
											!(userData && userData.avatar) ? 'tonal' : undefined
										"
									>
										<VImg
											v-if="userData && userData.avatar"
											:src="userData.avatar as string"
										/>
										<VIcon v-else icon="tabler-user" />
									</VAvatar>
								</VBadge>
							</VListItemAction>
						</template>

						<VListItemTitle class="font-weight-medium">
							{{ `${userData.first_name} ${userData.last_name}` }}
						</VListItemTitle>
						<VListItemSubtitle>{{
							(userData.role as DirectusRoles).name
						}}</VListItemSubtitle>
					</VListItem>

					<PerfectScrollbar :options="{ wheelPropagation: false }">
						<template v-for="item in userProfileList" :key="item.title">
							<VListItem v-if="item.type === 'navItem'" :to="item.to" link>
								<template #prepend>
									<VIcon :icon="item.icon" size="22" />
								</template>

								<VListItemTitle>{{ item.title }}</VListItemTitle>

								<template v-if="item.badgeProps" #append>
									<VBadge rounded="sm" class="me-3" v-bind="item.badgeProps" />
								</template>
							</VListItem>

							<VDivider v-else class="my-2" />
						</template>

						<div class="px-4 py-2">
							<VBtn
								block
								size="small"
								color="error"
								append-icon="tabler-logout"
								@click="logout"
							>
								Logout
							</VBtn>
						</div>
					</PerfectScrollbar>
				</VList>
			</VMenu>
			<!-- !SECTION -->
		</VAvatar>
	</VBadge>
</template>
